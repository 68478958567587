const components = {
  CareServices: "ContentfulSectionCareServices",
  Clinicians: "ContentfulSectionClinicians",
  ComparisonTable: "ContentfulSectionComparisonChart",
  CoordinatedCareTeam: "ContentfulSectionCoordinatedCareTeam",
  Faq: "ContentfulSectionFaq",
  Features: "ContentfulSectionFeatures",
  Doctors: "ContentfulSectionDoctors",
  Hero: "ContentfulSectionHero",
  HeroHeadline: "ContentfulSectionHeroHeadline",
  InsuranceBanner: "ContentfulSectionInsuranceBanner",
  Layout: "ContentfulSectionLayout",
  ListLayout: "ContentfulListLayout",
  Media: "ContentfulComponentMedia",
  MultiColumn: "ContentfulComponentMultiColumn",
  PaginatedScroll: "ContentfulSectionDynamicScroll",
  PatientJourney: "ContentfulSectionPatientJourney",
  PreFooter: "ContentfulSectionPreFooter",
  ProductOffering: "ContentfulSectionProductOffering",
  ServicesHero: "ContentfulSectionServicesHero",
  SplitContent: "ContentfulSectionSplitContent",
  SplitCards: "ContentfulSectionSplitCards",
  SplitContentWithRichText: "ContentfulSplitContentWithRichText",
  Statistics: "ContentfulSectionStatistics",
  Testimonials: "ContentfulSectionTestimonials",
  WhatWeDo: "ContentfulSectionWhatWeDo",
  WhatWeTreat: "ContentfulSectionWhatWeTreat",
  ResearchInterestForm: "ContentfulSectionResearchInterestForm",
}

export default components
