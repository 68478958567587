import React from "react"
import PropTypes from "prop-types"
import Layout from "components/blocks/layout"
import Media from "components/blocks/media"
import "./splitSection.scss"
import { renderClasses } from "utils/renderClasses"

/* Split Section */
/**
 * Section with content on the left and media on the right.
 */
const SplitSection = ({
  backgroundColor,
  className,
  image,
  imageBackgroundStyle,
  intro,
  layout = "50-50",
  reverse,
}) => {
  const appliedLayout = layout || "50-50"
  return (
    <Layout
      className={renderClasses(`g-split-section ${className}`, [
        [backgroundColor, `--${backgroundColor}`],
      ])}
      layout={appliedLayout}
      mobileLayout={imageBackgroundStyle === "tile-on-image" ? "CAB" : "ACB"}
      reverse={reverse}
      textLockup={intro}
    >
      <Media {...image} imageBackgroundStyle={imageBackgroundStyle} />
    </Layout>
  )
}

SplitSection.props = {
  backgroundColor: PropTypes.oneOf([
    "blue",
    "darkGreen",
    "green",
    "yellow",
    "pink",
    "teal",
    "ivory",
    "darkBlue",
    "darkPink",
    "corticaBlue",
  ]),
  className: PropTypes.string,
  image: PropTypes.shape(Media.props),
  imageBackgroundStyle: PropTypes.string,
  intro: PropTypes.shape(Layout.props),
  layout: PropTypes.string,
  reverse: PropTypes.bool,
}

SplitSection.propTypes = SplitSection.props

export default SplitSection
