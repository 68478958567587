import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import { gsap } from "gsap"
import { SplitText } from "gsap/SplitText"
import { useInView } from "react-hook-inview"
import { easeOut, longAnim } from "utils/gsapAnims"
import { renderClasses } from "utils/renderClasses"
import { separateChildrenFromLine } from "utils/separateChildTagsFromLine"
import Button from "components/elements/styledButton"
import Title from "components/elements/Title"
import Heading5 from "components/type/Heading5"
import Wysiwyg from "components/type/Wysiwyg"
import "./textLockup.scss"

const TextLockup = ({
  action,
  alignment = "left",
  eyebrow,
  shouldAnimateIn = true,
  text,
  title,
}) => {
  const [ref, inView] = useInView({ threshold: 0.65, unobserveOnEnter: true })
  const eyebrowRef = useRef()
  const titleRef = useRef()
  const textRef = useRef()
  const [split, setSplit] = useState()

  // Set initial opacity and y offsets to prepare for animation
  useEffect(() => {
    gsap.registerPlugin(SplitText)

    if (!shouldAnimateIn) return

    if (eyebrowRef?.current) {
      gsap.set(eyebrowRef.current, {
        opacity: 0,
        y: "1em",
      })
    }

    if (titleRef?.current) {
      const q = gsap.utils.selector(titleRef.current)
      const s = new SplitText(q(".g-heading-tag"), {
        linesClass: "line",
        type: "lines, words",
      })
      s.lines.forEach(line => {
        const [divs, otherTags] = separateChildrenFromLine(line)
        if (divs.length > 0) {
          divs.forEach(div => {
            div.innerHTML = div.innerHTML.trim()
          })
          gsap.set(divs, {
            opacity: 0,
            y: "1em",
          })
        }
        if (otherTags.length > 0) {
          otherTags.forEach(tag => {
            tag.innerHTML = tag.innerHTML.trim()
          })
          gsap.set(otherTags, {
            display: "inline-block",
            opacity: 0,
            y: "1em",
          })
        }
      })
      setSplit(s)
    }

    if (textRef?.current) {
      gsap.set(textRef.current, {
        opacity: 0,
        y: "1em",
      })
    }
  }, [setSplit, shouldAnimateIn, eyebrowRef])

  // Stagger in elements of the text lockup
  useEffect(() => {
    if (!shouldAnimateIn || inView === false) return

    gsap.set(".tl-prep", { opacity: 1 })

    const delay = 0.033
    const duration = longAnim
    let delayCount = 0

    // Eyebrow (if used)
    if (eyebrow) {
      gsap.to(eyebrowRef.current, {
        delay: delay * delayCount,
        duration: duration,
        ease: easeOut,
        opacity: 1,
        y: 0,
      })

      delayCount = delayCount + 1
    }

    // Title
    if (split !== undefined) {
      split.lines.forEach((line, index) => {
        const [divs, otherTags] = separateChildrenFromLine(line)
        const revert = () => {
          window.addEventListener("resize", () => split.revert(), {
            once: true,
          })
        }

        gsap.to(otherTags.concat(divs), {
          delay: delay * (delayCount + index),
          duration: duration,
          ease: easeOut,
          onComplete: revert,
          opacity: 1,
          y: 0,
        })
      })

      delayCount = delayCount + split.lines.length
    }

    // Paragraph (if used)
    if (text) {
      gsap.to(textRef.current, {
        delay: delay * delayCount,
        duration: duration,
        ease: easeOut,
        opacity: 1,
        y: 0,
      })
    }
  }, [eyebrow, inView, shouldAnimateIn, split, text])

  return (
    <div
      className={renderClasses(`text-lockup --${alignment}`, [
        [shouldAnimateIn, "tl-prep"],
      ])}
      ref={ref}
    >
      {eyebrow && <Heading5 ref={eyebrowRef}>{eyebrow}</Heading5>}
      {title && <Title {...title} ref={titleRef} />}
      {text && <Wysiwyg ref={textRef} text={{ ...text }} />}
      {action && <Button {...action} className="text-lockup__button" />}
    </div>
  )
}

TextLockup.props = {
  action: PropTypes.shape(Button.props),
  alignment: PropTypes.oneOf(["left", "center", "right"]),
  eyebrow: PropTypes.string,
  shouldAnimateIn: PropTypes.bool,
  text: PropTypes.shape(Wysiwyg.props),
  title: PropTypes.shape(Title.props),
}

TextLockup.propTypes = TextLockup.props

export default TextLockup
