import React from "react"
import PropTypes from "prop-types"
import { renderClasses } from "utils/renderClasses"
import Sticker from "./Sticker"
import Asset from "./Asset"
import "./media.scss"

const Media = ({
  className,
  hAlign = "center",
  height,
  imageBackgroundStyle,
  inset = "0",
  maxHeight,
  maxWidth,
  ratio,
  stickers,
  vAlign = "center",
  width,
  ...props
}) => {
  const aspectRatio = ratio
    ? `${ratio.width}${ratio.height ? ` / ${ratio.height}` : ""}`
    : "auto"

  return (
    <div
      className={renderClasses(
        `g-media g-media-halign-${hAlign} g-media-valign-${vAlign}`,
        [[className, className]]
      )}
      style={{
        aspectRatio: aspectRatio,
        height: height,
        maxHeight: maxHeight,
        maxWidth: maxWidth,
        padding: inset,
        width: width,
      }}
    >
      {stickers?.map((sticker, i) => (
        <Sticker {...sticker} key={i} />
      ))}
      <Asset
        {...props}
        aspectRatio={aspectRatio}
        height={height}
        imageBackgroundStyle={imageBackgroundStyle}
        width={width}
      />
    </div>
  )
}

Media.props = {
  ...Asset.props,
  className: PropTypes.string,
  hAlign: PropTypes.oneOf(["left", "center", "right"]),
  inset: PropTypes.string,
  maxHeight: PropTypes.string,
  maxWidth: PropTypes.string,
  path: PropTypes.string,
  /**
   Sets border-radius. Include the unit (i.e. `12px` or `100%`)
   */
  radius: PropTypes.string,
  ratio: PropTypes.shape({
    height: PropTypes.number,
    width: PropTypes.number.isRequired,
  }),
  stickers: PropTypes.arrayOf(PropTypes.shape(Sticker.props)),
  vAlign: PropTypes.arrayOf(["top", "center", "bottom"]),
}

Media.propTypes = Media.props

export default Media
